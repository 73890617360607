<template>
  <div class="page-card-demo  page-info-content">
    <detailTemplate :detail-config="detailConfig" />
  </div>
</template>
<script>
import DetailTemplate from '../../components/detailTemplate.vue'
import { guarGreditFundDetail } from '@/api/tradeFinancing.js'
export default {
  components: { DetailTemplate },
  data() {
    return {
      detailConfig: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      guarGreditFundDetail(this.$route.query.id, res => {
        if (res.code === 200) {
          this.detailData = res.data
          this.ifload = false
          if (this.detailData) {
            this.getDetailConfig()
            this.$forceUpdate()
          }
        }
      })
    },
    getDetailConfig() {
      this.detailConfig = [
        {
          title: '基本信息',
          dataType: 'list',
          labelWidth: 150,
          dataList: [
            {
              label: '授信资金方：',
              type: '',
              className: '',
              value: this.detailData.fundCmpName
            },
            {
              label: '担保方：',
              value: this.detailData.guarCmpName
            },
            {
              label: '授信额度：',
              type: 'money',
              value: this.detailData.creditAmt
            },
            {
              label: '额度到期日：',
              type: 'date',
              value: this.detailData.endDate
            },
            {
              label: '单户限额：',
              type: 'money',
              value: this.detailData.custAmt
            },
            {
              label: '融资利率(%年化)：',
              type: 'rateYear',
              value: this.detailData.execRate
            },
            {
              label: '融资手续费率(%年化)：',
              type: 'rateYear',
              value: this.detailData.execFee
            },
            {
              label: '可用额度：',
              type: 'money',
              value: this.detailData.abledAmt
            },
            {
              label: '已用额度：',
              type: 'money',
              value: this.detailData.usedAmt
            },
            {
              label: '冻结额度：',
              type: 'money',
              value: this.detailData.frozenAmt
            },
            {
              label: '备注：',
              className: 'width100',
              value: this.detailData.remarks
            }
          ]
        }
      ]
    }
  }

}
</script>
